<template>
    <h3
        :class="
            cn(
                'relative text-base font-normal mt-0 pb-4 mb-4 border-solid border-b border-gray-100 leading-6 text-gray-900 text-center',
                props.class
            )
        ">
        <slot />
    </h3>
</template>
<script setup lang="ts">
import type { HTMLAttributes } from "vue";

const props = defineProps<{
    class?: HTMLAttributes["class"];
}>();
</script>
